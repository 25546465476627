
body{
  background-color: #001D39;
  margin: 0;
  padding: 0;
  height: 100%;
  color: white;
}

.top-color{
      background: #001D39;
}

.header{
  padding-top: 20px;
}

.container{
  max-width: 960px;
  margin: auto;
}

.header-container{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.social-media ul{
  list-style: none;
  display: inline;
}

.social-media ul li {
  display: inline;
  padding-right: 20px;
}

.hero-block{
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-block h1{
  width: 70%;
  margin: auto;
  text-align: center;
  font-size: 50px;
  font-weight: 400;
}

.banner-bottom{
  width: 100%;
}


.banner-bottom img {
  width: auto;
  height: auto;
}

.hero-block{
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.d-flex{
  display: flex;
  justify-content: center;
  align-items: center ;
}
.about-block{
  padding-top: 40px;
  padding-bottom: 40px;
}

.about-block h2{
  font-size: 50px;
  padding-right: 20px;
}

.about-block p{
  font-size: 16px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-top: 10px;
}

.road-map, .faq{
  padding-bottom: 120px;
}

.map-card{
  background: rgba(0, 0, 0, 0.39);
  border-radius: 31px;
  padding: 20px 32px;
  width: 280px;
  margin-bottom: 20px;

}

.title h2{
  text-align: center;
  font-size: 40px;
  margin-top: 0px;
  padding-top: 60px;
  padding-bottom: 60px;
}
.road-map ul{
  list-style: none;
}
.road-map ul li{
  display: block;
  width: 100%;
  float: left;

}
.road-map ul li:nth-child(even){
  float: right;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 1px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.n-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 41.5%;
}

/* The circles on the timeline */
.n-container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #ffffff;
  top: 65px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}



/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background: rgba(0, 0, 0, 0.39);
  border-radius: 31px;
  position: relative;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .n-container {
    width: 75%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .n-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

.faq{
  width: 100%;
  display: block;
}

.faq-block{
  display: inline-block;
}

.faq .col{
  padding: 20px 30px;
  background: rgba(0, 0, 0, 0.39);
  border-radius: 31px;
  position: relative;
  min-height: 180px;
  margin-bottom: 20px;
  width: 40%;
  float: left;
  margin: 18px;
}
p{
  line-height: 23px;
  word-break: break-word;
}

.technical{
  width: 100%;
  display: block;
  padding-bottom: 120px;
}

.technical-block .col{
  padding-left: 50px;
  width: 50%;
}
.technical-block{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
}

.rerity-block{
  text-align: center;
  width: 80%;
  margin: auto;
  padding-bottom: 120px;
}

.team .team-block .col{
  width: 33.33%;
}

.team{
}

.team .team-block{
  display: flex;
  flex-grow: 1;
  text-align: center;
}
.team .team-block p{
  padding: 0px;
  margin: 0px;
}

.team .team-block h4{
  margin: 0px;
  font-size: 30px;
  padding-top: 20px;
}

.m-card{
  width: 29%;
  background: #CD0000;
  padding: 30px;
  text-align: center;
  font-size: 23px;
  margin: auto;
  border-radius: 30px 30px 0px 0px;
}

.bot-color{
  background: #001D39;
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 3317px;
}

.m-card p{
  padding: 0px;
  margin: 0px;
  line-height: 31px;
}

.footer{
  padding-top: 300px;
  background: #00172D;
  margin-top: -220px;
}

.footer-block {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.footer .container {
  max-width: 100%;
}

.footer-block ul{
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.footer-block ul li{
  display: inline;
  padding-right: 20px;
}

.footer-block h5{
  padding: 0px;
  padding-bottom: 10px;
  margin: 0px;
}

@media screen and (max-width: 600px) {
  .header{
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer-block{
    flex-direction: column;
  }
  .d-flex{
    flex-direction: column;
  }
  .about-block h2{
    padding: 20px;
  }
  .d-flex .col{
    padding-bottom: 20px;
  }
  .m-card{
    width: 77%;
  }
  .team .team-block{
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .team .team-block .col{
    width: 100%;
    margin-bottom: 20px;
  }
  .technical-block{
    flex-direction: column;
  }
  .technical-block .col{
    width: 80%;
  }
  .footer-block{
    align-items: center;
  }
  .title h2{
    width: 80%;
    margin: auto;
  }
  .faq .col{
    width: 80%;
  }
}



.wrapper{
  margin: auto;
  width: 80%;
}

.wrapper ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

.wrapper li{
  margin-bottom: 15px;
  background: #011223;
  border-radius: 20px;
  padding: 16px;
}

.wrapper label{
  font-size: 24px;
  display: block;
  cursor: pointer;
  padding: 11px;
  border-bottom: none;
  background: url("./plus.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.wrapper label:hover{

}

.wrapper label.last{

}

.wrapper ul ul li{
  padding: 10px;
}


.wrapper input[type="checkbox"]{
  position: absolute;
  left: -9999px;
}

.wrapper input[type="checkbox"] ~ ul{
  height: 0;
  transform: scaleY(0);
}

.wrapper input[type="checkbox"]:checked ~ ul{
  height: 100%;
  transform-origin: top;
  transition: transform .2s ease-out;
  transform: scaleY(1);
}

.wrapper input[type="checkbox"]:checked + label{
  background: url("./minus.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

/*
  Pagination
*/
.browse-collection-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8rem;
}
.browse-collection-btn {
  display: flex;
  width: 200px;
  height: 48px;
  background-color: #3f51b5;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}
.browse-collection-btn:hover {
  transition: all 0.2s;
  background-color: #2e3d94;
}

.browse-collection-container {
  max-width: 960px;
  margin: auto;
  padding: 0 40px;
  padding-bottom: 20rem;
}

.sol-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;
  margin-bottom: 3rem;
}

.sol-item {
  width: 100%;
  height: 250px;
}

.sol-item__img {
  width: 100%;
  height: 80%;
}

.sol-item__title {
  text-align: center;
}


.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center;
}

.page-link {
  position: relative;
  display: block;
  color: black;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: rgba(0, 0, 0, 0.6);
  background-color: #e9ecef;
  border-color: #dee2e6;
  cursor: pointer;
}
.page-link:focus {
  z-index: 3;
  color: rgba(0, 0, 0, 0.6);
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.2);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.39);
  border-color: rgba(0, 0, 0, 0.39);
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.navigation {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.navigation-item {
  padding: 0 40px;  /* Increase padding to add more horizontal space */
  font-size: 30px;  /* Increase font size to make the text bigger */
}

.navigation-item a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.half-width-image {
  width: 50% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.countdown {
  text-align: center;
  margin-bottom: 1rem;
}


/* MintPage.css */
.btn {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 100px; /* Add bottom margin here */
}

.containerA {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30%;
}

.input-containerA {
    margin-top: 20px;
    display: flex;
    flex-direction: column; /* Change the flex-direction to column */
    align-items: flex-start;
    gap: 1rem;
}
